import React from "react";
import Typography from "@mui/material/Typography";

import cssStyles from "./About.module.scss";

export function getAboutText() {
  return (
    <div className={cssStyles.about}>
      <Typography variant="body1" paragraph>
        Welcome to the official site of Palace Spice, Battersea, London! The
        name "Palace Spice" has been around since the early 90's. We offer you a
        delectable variety of cuisine inspired by the authentic home cooking of
        India and Bangladesh. Our aim is to introduce exotic flavours from these
        regions creating our own recipes, many of which have been passed down
        through generations of our family.
      </Typography>
      <Typography variant="body1" paragraph></Typography>
      Our dishes are prepared in our coal fired tandoori oven and traditional
      {/* cspell: disable-next-line */}
      haandi (copperpot) or tawah (iron griddle), giving them that authentic
      taste. Our highly skilled chefs are passionate about food, creating for
      you the most mouth watering tastes with the best quality meats, poultry,
      seafood and vegetables infused with the finest ingredients and using fresh
      whole spices, stimulating the palate to the very last bite.
      <Typography variant="body1" paragraph>
        With over 20 years of experience and an exemplary reputation, we have
        come to master what our customers desire. At Palace Spice we cater for
        the healthy 21st century without compromising the rich and scrumptious
        flavours that are the essence of Indian and Bangladeshi Cuisine.
      </Typography>
    </div>
  );
}

export function getAdditionalAboutText() {
  return null;
}

export function getAccolades() {
  return [];
}

export function getGalleryMenuImages() {
  return [];
}

export function getGalleryRestaurantImages() {
  return [];
}
