const getPalette = (theme) => {
  return {
    type: "light",
    primary: { main: "#922572", hover: "#CC37A1" },
    secondary: { main: "#922572", hover: "#CC37A1" },
    accent: "#922572",
    info: {
      main: "#922572",
    },
    error: {
      main: "#FF0040",
    },
    white: {
      main: "#FFF",
    },
  };
};

export default getPalette;
